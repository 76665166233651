<template>
  <!-- 文案溢出，显示tooltip -->
  <div id="table-toolTip" class="el-tooltip__popper is-dark" style="transform-origin: center top; z-index: 2003; position: fixed; display: none;">
    {{ tableCellTooltipText }}
    <div id="toolTip-arrow" class="popper__arrow"></div>
  </div>
</template>

<script>
export default {
  props: {
    tableCellMouse: {
      type: Object,
      default() {
        return {};
      },
    }
  },

  data() {
    return {
      toolDom: null,
      arrowDom: null,
      tableCellTooltipText: null, // 溢出文案
      mouseLeaveVal: {
        tooltip: 0, // 1-鼠标移入，2-鼠标移出，0-无焦点
        cell: false // 单元格
      }
    };
  },

  computed: {},

  watch: {
    mouseLeaveVal: {
      deep: true,
      handler(nv) {
        setTimeout(() => {
          if (nv.cell && 1 != nv.tooltip) {
            this.toogleActiveTooltip(false, this.toolDom);
          }
        }, 100);
      }
    },

    tableCellMouse: {
      deep: true,
      handler(nv, ov) {
        if (nv.hidden) {
          this.hiddenTooltip();
        } else if (nv.row && !nv.hidden) {
          this.showTooltip(nv.cellDom);
        }
      }

    }
  },

  created() {},

  mounted() {
    this.$nextTick(() => {
      this.toolDom = document.getElementById("table-toolTip");
      this.arrowDom = document.getElementById("toolTip-arrow");
      const _this = this;
      this.toolDom.addEventListener("mouseleave", event => {
        _this.mouseLeaveVal.tooltip = 2;
      });
      this.toolDom.addEventListener("mouseenter", event => {
        _this.mouseLeaveVal.tooltip = 1;
      });
    });
  },

  methods: {
    // 文字溢出显示tooltip  动态计算top和left
    showTooltip(cell) {
      this.tableCellTooltipText = cell.innerText || cell.textContent;
      const textWidth = this.textSize("12px", this.tableCellTooltipText);
      if (textWidth > cell.clientWidth - 25) {
        const cellLeft = cell.getBoundingClientRect().left,
            cellTop = cell.getBoundingClientRect().top;
        const computedPositonNum = this.hiddenToolPosition(textWidth, cell.clientWidth, cellLeft, cellTop);
        this.toolDom.style.top = `${computedPositonNum.top}px`;
        this.toolDom.style.left = `${computedPositonNum.left}px`;
        this.toolDom.style.width = `${computedPositonNum.width}px`;
        this.arrowDom.style.left = `${computedPositonNum.arrowLeft}px`;
        this.toogleActiveTooltip(true, this.toolDom);
        this.mouseLeaveVal.cell = false;
        this.mouseLeaveVal.tooltip = 0;
        this.toolDom.style.display = "";
      } else {
        this.toolDom.style.display = "none";
      }
    },

    // toolTip定位计算
    hiddenToolPosition(txtWidth, cellWidth, cellLeft, cellTop) {
      const toolHight = Math.ceil((txtWidth + 8) / 310) * 24 + 20,
          toolWidth = 300 < txtWidth ? 500 : (txtWidth + 50),
          webWidth = document.body.offsetWidth;
      // webHeight = document.body.offsetHeight;
      let expectLeft = cellLeft - (toolWidth - cellWidth) / 2;
      const toolWidthAndLeft = expectLeft + toolWidth,
          expectTop = cellTop - toolHight;
      let arrowLeft; // arrow left
      if (toolWidthAndLeft > webWidth) {
        const moreWidth = toolWidthAndLeft - webWidth;
        expectLeft -= (toolWidthAndLeft - webWidth);
        arrowLeft = (toolWidth - moreWidth) / 2 + moreWidth - 6;
      } else {
        arrowLeft = (toolWidth / 2) - 6;
      }
      return { left: expectLeft, top: expectTop, width: toolWidth, arrowLeft };
    },

    hiddenTooltip() {
      this.mouseLeaveVal.cell = true;
    },

    //tooltip样式
    toogleActiveTooltip(show, toolDom) {
      if (show) {
        toolDom.classList.add("el-fade-in-linear-enter-active", "el-fade-in-linear-enter-to", 'el-popper');
        setTimeout(() => {
          toolDom.classList.remove("el-fade-in-linear-enter-active", "el-fade-in-linear-enter-to");
        }, 500);
        toolDom.style.display = "";
      } else {
        toolDom.classList.add("el-fade-in-linear-leave-active", "el-fade-in-linear-leave-to");
        setTimeout(() => {
          toolDom.classList.remove("el-fade-in-linear-leave-active", "el-fade-in-linear-leave-to", 'el-popper');
        }, 500);
        toolDom.style.display = "none";
      }
    },

    textSize(fontSize, text) {
      const span = document.createElement("span");
      let width = span.offsetWidth;
      span.style.visibility = "hidden";
      span.style.fontSize = fontSize;
      span.style.display = "inline-block";
      document.body.appendChild(span);
      if ("undefined" != typeof span.textContent) {
        span.textContent = text;
      } else {
        span.innerText = text;
      }
      width = Math.ceil(span.clientWidth);
      document.body.removeChild(span);
      return width;
    },
  }
};
</script>

<style lang="scss" scoped>
#table-toolTip {
  padding: 30px 30px;
  .el-tooltip__popper {
    max-width: 600px !important;
    box-sizing: border-box;
  }
  .popper__arrow {
    bottom: -6px;
    left: 50%;
    margin-right: 3px;
    border-bottom-width: 0;
    border-top-color: blue;
    &::after {
      border-bottom-width: 0;
      border-top-color: blue;
      bottom: 1px;
      margin-left: -6px;
    }
  }
}
</style>